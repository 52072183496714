import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { StyledCallToActionBlock, StyledFlamingoHeadWrapper } from '@/components/CallToActionBlock/styles';
import Button from '@/components/Button';
import { sendMail } from '@/lib/api';
import Icon from '../Icon';

const CallToActionBlock = ({ className, configuration, bottom, flamingo }) => {
    const { type, title, subtitle, button_text, url, image, input_fields, image_aside, file } = configuration;
    const contentWidth = image_aside ? 'w-full md:w-8/12' : 'w-full';

    const [isSent, setIsSent] = useState(false);
    const isDownload = type === 'download';

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const getTailwindWidth = (apiWidth) =>
        ({
            half: 'sm:col-span-3',
            third: 'sm:col-span-2',
            two_third: 'sm:col-span-4',
            full: 'sm:col-span-6',
        }[apiWidth]);

    const getInputType = (fieldType) =>
        ({
            name: 'text',
            e_mail: 'email',
            website_url: 'url',
            phone_number: 'tel',
            newsletter: 'checkbox',
        }[fieldType]);

    const onSubmit = async (data) => {
        if (type === 'url') {
            window.open(url, url.startsWith('/') ? '_self' : '_blank');
            return;
        }

        const result = await sendMail(
            {
                cta_type: type,
                title,
                url: window.location.href,
                file_id: file?.id || null,
                fields: data,
            },
            true
        );

        if (result.code === 200) {
            setIsSent(true);
        }
    };

    const activeContainer = () => {
        // Component was sent, is of type download and has a file
        if (isSent && isDownload && file?.url?.length > 0) {
            return (
                <>
                    <div className="w-full font-display font-black text-lg sm:text-3xl lg:text-4xl call-me-back-title tracking-wide text-grey-white mb-6">
                        Het is gelukt!
                    </div>
                    <Button
                        as="a"
                        backgroundColor="blue"
                        href={`${process.env.NEXT_PUBLIC_STRAPI_API_URL}${file.url}`}
                        target="_blank"
                        id={`#${type}`}
                    >
                        Klik hier om het bestand te downloaden
                    </Button>
                </>
            );
        }

        if (isSent) {
            return (
                <>
                    <div className="w-full font-display font-black text-lg sm:text-3xl lg:text-4xl call-me-back-title tracking-wide text-grey-white mb-6">
                        Het is gelukt!
                    </div>
                    <div className="w-full font-display font-black fill-current text-lg sm:text-xl lg:text-2xl call-me-back-subtitle tracking-wide text-blue bg-gradient-to-r from-blue to-blue-light">
                        We nemen binnenkort contact met je op.
                    </div>
                </>
            );
        }

        return (
            <>
                {title ? (
                    <div className="w-full font-display font-black text-lg sm:text-2xl lg:text-3xl call-me-back-title tracking-wide text-grey-white mb-3">
                        {title}
                    </div>
                ) : null}

                {subtitle ? (
                    <div className="w-full font-display font-black fill-current text-md sm:text-lg lg:text-xl call-me-back-subtitle tracking-wide text-blue bg-gradient-to-r from-blue to-blue-light">
                        {subtitle}
                    </div>
                ) : null}

                {image?.data?.attributes.url ? (
                    <div className="flex justify-center pt-4">
                        <div className="w-36">
                            <Image
                                className="w-full h-full block"
                                src={`${process.env.NEXT_PUBLIC_STRAPI_URL}/${
                                    image.data?.attributes.url.startsWith('/')
                                        ? image.data?.attributes.url.substring(1)
                                        : image.data?.attributes.url
                                }`}
                                alt={image.data?.attributes.alternativeText || ''}
                                width={image.data?.attributes.width}
                                height={image.data?.attributes.height}
                                layout="intrinsic"
                            />
                        </div>
                    </div>
                ) : null}

                <form className="w-full mt-4 sm:grid sm:grid-cols-6 gap-4 pb-4" onSubmit={handleSubmit(onSubmit)}>
                    {type !== 'url' &&
                        input_fields?.map((field, index) => {
                            const tailwindWidth = getTailwindWidth(field.width);
                            const errorClass = errors[field.type]
                                ? 'placeholder-pink-soft border-2 border-pink-soft'
                                : '';

                            if (field.label) {
                                const inputType = getInputType(field.type);
                                const isCheckbox = inputType === 'checkbox';

                                const fieldClasses = isCheckbox ? 'items-start' : 'h-24 items-end';
                                const magnetClasses =
                                    configuration.__component === 'frontend.magnet-blok'
                                        ? 'col-span-6'
                                        : `${tailwindWidth}`;

                                return (
                                    <div
                                        className={`relative mt-2 sm:mt-0 flex  ${fieldClasses} ${magnetClasses}`}
                                        key={index}
                                    >
                                        {!isCheckbox && (
                                            <label
                                                htmlFor={inputType}
                                                className="absolute top-0 left-0 font-bold text-grey-white"
                                            >
                                                {field.label_text}
                                            </label>
                                        )}
                                        <input
                                            id={inputType}
                                            key={index}
                                            type={inputType}
                                            placeholder={field.placeholder}
                                            className={
                                                inputType === 'checkbox'
                                                    ? 'w-4 h-4 mr-2 my-1'
                                                    : `rounded p-4 col-span-6 font-light text-text text-body w-full h-16 ${errorClass}`
                                            }
                                            {...register(field.type, { required: !isCheckbox })}
                                        />
                                        {isCheckbox && (
                                            <label
                                                htmlFor={inputType}
                                                className="text-grey-white text-[.9rem] mt-[.1rem]"
                                            >
                                                {field.label_text}
                                            </label>
                                        )}
                                    </div>
                                );
                            }

                            return (
                                <input
                                    key={index}
                                    type={getInputType(field.type)}
                                    placeholder={field.placeholder}
                                    className={`rounded p-4 col-span-6 font-light text-text text-body w-full h-16 ${
                                        configuration.__component === 'frontend.cta-blok'
                                            ? 'mt-4 sm:mt-8'
                                            : 'mt-2 md:mt-0'
                                    } ${tailwindWidth}  ${errorClass}`}
                                    {...register(field.type, { required: true })}
                                />
                            );
                        })}
                    <Button
                        className="cursor-pointer col-span-6 mt-4 sm:mt-0 w-full"
                        backgroundColor="blue"
                        type="submit"
                        id={`#${type}`}
                    >
                        {button_text}
                        <Icon name="arrow" size={24} className="sm:absolute right-4 icon" />
                    </Button>
                </form>
            </>
        );
    };

    return (
        <div className="flex flex-col relative">
            <div className={`relative flex justify-center w-full container big mb-0 ${bottom ? 'lg:-mb-10' : ''}`}>
                <section className="pt-16 md:pt-6 app-m-w">
                    <div className="md:flex md:flex-wrap">
                        <StyledCallToActionBlock className="w-full">
                            <div
                                className={`p-5 bg-call-me-back-ripple bg-right-top bg-no-repeat w-full bg-text xl:rounded-md ${className}`}
                            >
                                <div className="flex w-full h-full border-box relative">
                                    <div className={`call-me-text-wrapper ${contentWidth}`}>{activeContainer()}</div>
                                    <div className="flex-1 p-4">
                                        {/* Often image of person */}
                                        {image_aside?.data?.attributes.url && (
                                            <Image
                                                className="relative"
                                                src={`${process.env.NEXT_PUBLIC_STRAPI_URL}/${
                                                    image_aside.data?.attributes.url.startsWith('/')
                                                        ? image_aside.data?.attributes.url.substring(1)
                                                        : image_aside.data?.attributes.url
                                                }`}
                                                alt={image_aside.data?.attributes.alternativeText || ''}
                                                width="100"
                                                height="100"
                                                layout="responsive"
                                                objectFit="contain"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </StyledCallToActionBlock>
                    </div>
                </section>
            </div>
            {bottom && (
                <div className="footer-extender w-full bg-grey-ghostWhite pb-16">
                    {flamingo && (
                        <StyledFlamingoHeadWrapper className="absolute">
                            <a
                                href="https://nl.wikipedia.org/wiki/Flamingo%27s"
                                rel="noopener noreferrer nofollow"
                                target="_blank"
                            >
                                <Image
                                    width="59"
                                    height="46"
                                    src="/images/flamingo-head.png"
                                    title="Meer weten over Hero?"
                                />
                            </a>
                        </StyledFlamingoHeadWrapper>
                    )}
                </div>
            )}
        </div>
    );
};

CallToActionBlock.propTypes = {
    className: PropTypes.string,
    configuration: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        button_text: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        __component: PropTypes.string,
        input_fields: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                width: PropTypes.string,
                placeholder: PropTypes.string,
            })
        ),
        image: PropTypes.shape({
            data: PropTypes.shape({
                attributes: PropTypes.shape({
                    url: PropTypes.string,
                    width: PropTypes.number,
                    height: PropTypes.number,
                    alternativeText: PropTypes.string,
                }),
            }),
        }),
        image_aside: PropTypes.shape({
            data: PropTypes.shape({
                attributes: PropTypes.shape({
                    url: PropTypes.string,
                    alternativeText: PropTypes.string,
                }),
            }),
        }),
        file: PropTypes.shape({
            id: PropTypes.number,
            url: PropTypes.string,
        }),
    }),
    bottom: PropTypes.bool,
    flamingo: PropTypes.bool,
};

export default CallToActionBlock;
