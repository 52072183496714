import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { variables } from '@/assets/styled';

const StyledButton = styled.button`
    outline: none !important;
    transition: transform 200ms ease, box-shadow 300ms ease;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

    &:hover {
        transform: translateY(-1px);
    }

    &.pink:hover {
        box-shadow: 5px 5px 27px -13px ${variables.colors.pink.soft};
    }

    &.blue:hover {
        box-shadow: 5px 5px 27px -13px ${variables.colors.blue.default};
    }
`;

const backgroundColors = {
    pink: 'text-grey-white pink bg-gradient-to-r from-orange to-pink-soft',
    'pink-secondary': 'text-pink border border-pink text-pink bg-transparent',
    blue: 'text-grey-white  blue bg-gradient-to-r from-blue to-blue-light',
    transparent: 'bg-transparent',
};

const Button = ({ className = '', children, backgroundColor, small, ...props }) => {
    const baseStyles =
        'relative font-display w-auto rounded font-bold tracking-wider shadow-md inline-flex items-center';
    const backgroundStyles = backgroundColors[backgroundColor] ? backgroundColors[backgroundColor] : '';
    const sizeStyles = small ? 'px-3 py-2 text-sm' : 'px-5 py-4 text-md lg:text-lg';

    return (
        <StyledButton className={`${baseStyles} ${backgroundStyles} ${sizeStyles} ${className}`} {...props}>
            {children}
        </StyledButton>
    );
};

Button.propTypes = {
    children: PropTypes.any,
    url: PropTypes.string,
    backgroundColor: PropTypes.oneOf(['blue', 'pink', 'pink-secondary', 'transparent']),
    className: PropTypes.string,
    small: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Button;
