import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledCallToActionBlock = styled.div`
    z-index: 10;

    .call-me-back-inner {
        background-size: 60% auto;
    }

    .call-me-back-subtitle {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (min-width: ${variables.screens.xs}) {
        .call-me-image-wrapper {
            height: 200px;
        }
    }

    @media (min-width: ${variables.screens.lg}) {
        .call-me-image-wrapper {
            height: 270px;
        }
    }
`;

export const StyledFlamingoHeadWrapper = styled.div`
    bottom: 7rem;
    right: 100px;
    z-index: 15;
    display: none;

    &.left {
        right: auto;
        left: 100px;
        transform: scaleX(-1);
    }

    @media (max-width: ${variables.screens.lg}) {
        top: 29px;
        display: block;
        bottom: auto;
        right: 1.25rem;
        left: auto;
        animation: miniHeroRight 3s infinite;

        &.left {
            animation: miniHeroLeft 3s infinite;
        }
    }

    @media (min-width: 1440px) {
        display: none;
        right: 1rem;
    }

    @media (min-width: calc(1600px)) {
        display: block;
        right: 1.25rem;
    }

    @keyframes miniHeroRight {
        0%,
        100% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-3rem);
        }
    }

    @keyframes miniHeroLeft {
        0%,
        100% {
            transform: translateX(0) scaleX(-1);
        }
        50% {
            transform: translateX(-3rem) scaleX(-1);
        }
    }
`;
